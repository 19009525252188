(function () {
    'use strict';

    angular.module('common')
        .factory('ShowUserProfileModal', ShowUserProfileModal);

    ShowUserProfileModal.$inject = ['$uibModal'];

    function ShowUserProfileModal($uibModal) {
        return {
            open: open
        };

        function open(user, vendorLogoId, organizationId) {
            var modal = $uibModal.open({
                size: "md",
                templateUrl: "/static/templates/common/userProfileModal.html",
                resolve: {
                    user: function () {
                        return user;
                    },
                    vendorLogoId: function () {
                        return vendorLogoId;
                    },
                    organizationId: function () {
                        return organizationId
                    }
                },
                controller: ["$scope", "$state", "$http", "user", "vendorLogoId", "organizationId", "CommonService",
                    "routesConstant", function ($scope, $state, $http, user, vendorLogoId, organizationId,
                                                CommonService, routesConstant) {
                        $scope.user = {
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.email,
                            preferredUnitOfMeasure: user.preferredUnitOfMeasure,
                            preferredLossDistanceUnit: user.preferredLossDistanceUnit,
                            username: user.username
                        };
                        $scope.unitsOfMeasure = [
                            "Kilometers",
                            "Miles",
                            "Kilofeet",
                            "Feet",
                            "Meters"
                        ];
                        $scope.unitsOfLossDistance = [
                            "dB/km",
                            "dB/kft",
                            "dB/mi"
                        ];
                        $scope.timezones = [];

                        $scope.changePassword = function () {
                            CommonService.changePassword($scope.user.username)
                                .then(function (rtn) {
                                    if (rtn.data.token) {
                                        window.location.href = CommonService.baseUrl +  "/password/"
                                            + encodeURIComponent($scope.user.username) + "/" + rtn.data.token;
                                    } else {
                                        $state.go(routesConstant.LOGIN.LOGOUT.stateName);
                                    }
                                });
                        };

                        $scope.saveProfile = function () {
                            CommonService.saveProfile(organizationId, $scope.user)
                                .then(function (response) {
                                    modal.close();
                                })
                        };
                    }]
            });

            return modal.result;
        }
    }
})();
